<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="true"
                :title="'POS Invoice'"
                btnTitle="New Bill"
                :showExtraField="true"
                :extraFieldData="'Shop/Location Name'"
                @onClickCloseButton="closeButton"
                :locations="locations"
            />
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="chat-fixed-search p-2">
                    <div class="d-flex align-items-center w-100">
                        <div class="input-group input-group-merge ms-1 w-100">
                          <span class="input-group-text round"><i data-feather="search" class="text-muted"></i></span>
                          <input v-model="searchTerm" type="text" class="form-control round"
                            id="chat-search"
                            placeholder="Quick Search.." aria-label="Search..."
                            aria-describedby="chat-search"
                          />
                        </div>
                        <div class="fw-bold h4 sidebar-profile-toggle p-2 mb-0">
                            Filter
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 py-2 ">
                <div class="py-1 px-2 px-md-0">
                    <div class="d-flex align-items-center">
                        <div class="col-6">
                            <v-select
                                placeholder="Select Party"
                                v-model="contactProfileId"
                                :options="contacts"
                                label="name"
                                :reduce="name => name.id"
                            />
                        </div>
                        
                        <div class="col-4 d-flex justify-content-center">
                            <span> Reward Point </span>
                        </div>

                        <button class="btn btn-primary btn-sm"  @click="openPatientAddModal">
                            +
                        </button>
                    </div>
                </div>
            </div>
            <PatientAddModal ref="patientAdd" :isOpen="isModal"/>
            <ItemCard v-if="!loader"
                :items="productList"
                :cashAndBack="cashAndBack"
                :contactProfileId="contactProfileId"
                :companyId="companyId"
            />

            <Loader v-if="loader"/>
        </div>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButtonHospital';
    import ItemCard from '@/components/molecule/company/hospital/PosInvoiceItemCard.vue';
    import PatientAddModal from '@/components/molecule/company/hospital/PatientAddModal'
    import handleInventory from '@/services/modules/inventory';
    import Loader from '@/components/atom/LoaderComponent'
    import { onMounted, ref } from 'vue';
    import {useRoute, useRouter} from "vue-router";
    import handleReceipt from '@/services/modules/receipt'
    import handleContact from '@/services/modules/contact'
    import handleBusinessesLocations from '@/services/modules/businessesLocations'

    const productList = ref([]);
    const patientAdd = ref(null);
    const loader = ref(true);
    const $route = useRoute();
    const $router = useRouter;
    const cashAndBack = ref([]);
    const contactProfileId = ref(null);
    const companyId = $route.params.companyId
    const companyQuery = `?company_id=${companyId}`
    const contacts = ref([]);
    const locations = ref([])
    const { fetchProductList } = handleInventory();
    const { fetchCashAndBank } = handleReceipt();
    const {fetchContactProfiles} = handleContact();
    const {fetchBusinessLocationsList} = handleBusinessesLocations();
    const businessLocationRes = fetchBusinessLocationsList(companyQuery)

    onMounted(()=>{
        getItems();
        getCashAndBank();
        getContacts();
        businessLocationRes.then(res=> {
          if(res.data) locations.value = res.data
        });
    })

    const getContacts = async () => {
        try{
            const res = await fetchContactProfiles(companyQuery);
            if(res.data){
                contacts.value = res.data;
            }
        }catch(e){
            console.log('Something went wrong');
        }
    }

    const getItems = async () => {
        const query = `?company_id=${companyId}&product_type=finished_goods`
        try{
            const { data } = await fetchProductList(query);
            if(data.length == 0){
                return
            }
            productList.value = data 
        }catch(e){
            console.log("Something went wrong");
        }
        loader.value = false
    }

    const getCashAndBank = async () => {
        try{
            let res = await fetchCashAndBank(companyId)
            if(!res.status) {
                this.showError(res.message)
                return
            }
            cashAndBack.value = res.data
        }catch(e){
            console.log("Something went wrong");
        }
    }

    const openPatientAddModal = () => {
        patientAdd.value.toggleModal();
    }
</script>