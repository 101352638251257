<template>
    <div v-if="isModalOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ modalTitle }}</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                
                <div class="pb-2">
                    <!-- form -->
                    <vForm @submit="submit" :validation-schema="schema">
                        <div class="col-11 py-1 mx-auto">
                            <div class="mb-1">
                                <label class="form-label" for="fullName">Full Name</label>
                                <vField
                                    name="amount text-right"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                            <div class="mb-1">
                                <label class="form-label" for="mobileNo">Mobile No</label>
                                <vField
                                    name="amount text-right"
                                    type="number"
                                    class="form-control text-right"
                                />
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="mb-1 col-5">
                                <label class="form-label" for="age">Age</label>
                                <vField
                                    name="amount text-right"
                                    type="number"
                                    class="form-control text-right"
                                />
                                </div>
                                <div class="mb-1 col-6">
                                    <label class="form-label" for="gender">Gender</label>
                                    <v-select
                                        placeholder="Select Gender"
                                        :options="gender"
                                        label="name"
                                    />
                                </div>
                            </div>
                            <div class="mb-1">
                                <label class="form-label" for="gender">Referrer</label>
                                <v-select
                                    placeholder="Select Referrer"
                                    label="name"
                                />
                            </div>
                        </div>

                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button :disabled="loading" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Submit
                            </button>
                            <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </vForm>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PatientAddModal',
    
    data() {
        return{
            gender: [{name: 'Male', id: 1}, {name: 'Female', id: 2}, {name: 'Others', id: 2}],
            isModalOpen: false
        }
    },

    props: {
        modalTitle: {
            type: String,
            default:"Add Patient"
        }
    },
    
    methods: {
        toggleModal () {
            this.isModalOpen = !this.isModalOpen
        }
    }
}
</script>