<template>
    <div class="row">
        <div class="col-md-6">
            <div class="user-chats">
                <div class="chats">
                    <ul class="nav nav-tabs">
                        <li class="nav-item"> <span @click="getProducts(-1)" class="nav-link cursor-pointer">All</span> </li>
                        <li v-for="(item, index) in items" :key="index" class="nav-item">
                            <span
                              @click="getProducts(item.id)"
                              class="nav-link cursor-pointer"
                            >
                                {{ item.name }}
                            </span>
                        </li>
                        <li v-if="items.length > 2" class="nav-item"> <span class="nav-link">More</span> </li>
                    </ul>

                    <div class="row p-2">
                        <div v-for="(item, index) in products" :key="index" class="col-4 cursor-pointer" @click="addItem(item)">
                            <img src="/app-assets/images/product-photo.png" class="image-width"/>
                            <p>{{ item.name }}</p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="d-flex justify-content-between">
                <h4>TOTAL BILL AMOUNT = </h4>
                <h4> 
                    <vField
                        v-model="totalAmount"
                        readonly
                        name="total_amount"
                        type="number"
                        class="form-control text-right"
                    >  
                    </vField>
                </h4>
            </div>
            <div class="table-container mb-20">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Qty</th>
                            <th class="text-right">Price</th>
                            <th class="text-right">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in data" :key="index">
                            <th class="text-overflow">{{ item.name }}</th>
                            <th class="text-right">
                                <input
                                    v-model="item.quantity"
                                    name="quantity"
                                    type="number"
                                    class="form-control text-right p-0"
                                    @input="changeQty(item.quantity, index)"
                                /> 
                            </th>
                            <th class="text-right">{{ item.rate }}</th>
                            <th class="text-right px-0">
                                <vField
                                    class="form-control text-right p-0"
                                    name="amount"
                                    v-model="item.total_amount" readonly
                                >
                                </vField>
                            </th>
                            <th><span class="cursor-pointer" @click="deleteTableItem(index)">X</span></th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pay-now">
                <h4 class="p-1 pay">Pay Now</h4>
            </div>
            <div class="d-flex mt-2">
                <div class="col-6 p-1">
                    <label for="colFormLabel" class="col-form-label">Received in</label>
                    <v-select
                        placeholder="Select Cash & Bank Account"
                        class="w-100"
                        v-model="account_head_id"
                        :options="cashAndBack"
                        label="name"
                        :reduce="name => name.id"
                    />
                    <input type="text" class="form-control mt-1" placeholder="MoP Reference"/>
                    <div class="d-flex justify-content-between mt-1">
                        <label class="form-label" for="vat">VAT AMOUNT</label>
                        <vField
                            v-model="vat"
                            id="vat"
                            as="select"
                            name="vat"
                            class="form-select"
                        >
                            <option
                                v-for="(vat, i) in vatRate"
                                :value="vat.value"
                                :key="i"
                            >
                                {{vat.label}}
                            </option>
                        </vField>
                    </div>
                </div>
                
                <div class="col-6 p-1">
                    <div class="d-flex justify-content-between mb-2">
                        <label class="form-label" for="total_amount">Total Amount</label>
                        <vField
                            v-model="totalAmount"
                            readonly
                            name="total_amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <label class="form-label" for="discount-percent">Discount Percent</label>
                        <vField
                            v-model="discountPercent"
                            name="discount-percent"
                            type="number"
                            class="form-control text-right"
                            max="100"
                            min="0"
                        />
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <label class="form-label" for="discount-amount">Discount Amount</label>
                        <vField
                            v-model="discountAmount"
                            name="discount-amount"
                            type="number"
                            class="form-control text-right"
                            min="0"
                        />
                    </div>
                    <div class="button mt-3">
                        <vField
                            v-model="totalAmount"
                            readonly
                            name="total_amount"
                            type="number"
                            class="form-control text-right"
                        >  
                        </vField>
                        <button class="btn btn-primary w-100" @click="submitData">
                            Print
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-2 position-absolute bottom-0">
            <Pagination
                :offset="20"
                @onPageChange="onPageChange"
                ref="pagination"
            />
        </div>
    </div>

</template>

<script>
import Pagination from '@/components/atom/Pagination';
import {vatRate} from '@/data/inventory.js'
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import handlePurchase from '@/services/modules/purchase'

export default {
    name: 'ItemCard',

    components: {
        Pagination
    },
    
    props: {
        items: Array,
        cashAndBack: Array,
        contactProfileId: null,
        companyId: null
    },

    data() {
        return{
            showItems: [],
            products: [],
            currentPage: 1,
            formData: {
                company_id: null,
                contact_profile_id: null,
                account_head_id: null,
                vat_payable_account_head_id: null,
                business_id: null,
                project_id: null,
                cost_centre_id: null,
                location_id: null,
                status: 'active',
                bill_number: '',
                date: '',
                has_item_detail: true,
                description: '',
                order_number: null,
                sales_person_id: null,
                commission_percent: null,
                lc_number: null,
                item_details: []
            },
            data: [],
            vatRate: vatRate,
            vat: 0,
            discountAmount: 0,
            discountPercent: 0,
            voucher: ''
        }
    },

    mounted(){
        this.getProducts();
        this.getVoucher();
    },

    methods: {
        async submitData(){
            this.organizeData();
            const handler = handlePurchase();
            try{
                const res = await handler.storeBillPayment(this.formData);
                if(res.status){
                    console.log(res);
                }
            }catch(e){
                console.log('Something went wrong');
            }
        },

        organizeData(){
            this.formData.company_id = this.companyId;
            this.formData.bill_number = this.voucher.prefix+'-'+this.voucher.serial
            this.formData.account_head_id = this.account_head_id
            this.formData.date = this.getCurrentDate(new Date());
            this.formData.item_details = this.data;
            this.formData.contact_profile_id = this.contactProfileId;
        },

        async getVoucher(){
            this.voucher = await generateTxnNumber(`?company_id=${this.companyId}&voucher_type=journal_voucher&txn_type=invoice_journal`);
        },

        addItem(item){
            var newItem = item;
            var product = {
                name: item.name
            };
            newItem = { ...newItem, quantity: 1, rate: 100, vat: this.vat, product};
            var amount = (newItem.rate * newItem.quantity);
            const { total_amount, vat_amount } = this.getAmount(newItem.rate, newItem.quantity);
            newItem = { ...newItem, amount, total_amount, vat_amount}
            
            this.data.push(newItem);
        },

        getAmount(rate, quantity){
            var amount = rate * quantity;
            var vat_amount = 0;
            if(this.discountAmount > 0){
                amount = amount - this.discountAmount;
            }
            if(this.discountPercent > 0){
                amount = amount - parseInt((amount * this.discountPercent) / 100);
            }
            if(this.vat > 0){
                amount = amount + parseInt((amount * this.vat) / 100)
                vat_amount = (this.vat/100 * amount).toFixed(2)
            }
            var total_amount = amount;
            return {
                total_amount,
                vat_amount
            };
        },

        deleteTableItem(index){
            this.data.splice(index, 1)
        },

        getProducts(id = -1){
            this.products = [];
            this.items.forEach(item => (id === -1) ? this.getData(item, this.products) : (id === item.id) && this.getData(item, this.products));
        },

        getData(item, products){
            (item.child && item.child.length) > 0 ? item.child.forEach(ch => this.getData(ch, products)) :  (item.type === "endpoint") && (this.products.push(item));
        },

        onPageChange(page){
            let routeQuery = Object.assign({}, this.$route.query)
            routeQuery.page = page
            this.$router.push({path: this.$route.path, query: routeQuery})
            setTimeout(() => {
                this.getProducts();
            },100)
        },

        changeQty(quantity, index){
            if(quantity != 0 || quantity != ''){
                this.data[index].amount = this.data[index].rate * quantity;
                const { total_amount, vat_amount } = this.data[index].total_amount = this.getAmount(this.data[index].rate, quantity);
                this.data[index] = { ...this.data[index], total_amount, vat_amount };
            } 
        },

        getCurrentDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        },

        updateAllData(){
            this.data.map((el) => {
                const {total_amount, vat_amount} = this.getAmount(el.rate, el.quantity)
                el.total_amount = total_amount
                el.vat_amount = vat_amount
            })
        }
    },

    watch: {
        vat(newData, oldData){
            this.data.map(el => el.vat = this.vat);
            this.updateAllData();
        },

        discountAmount(newData, oldData){
            this.updateAllData();
        },

        discountPercent(newData, oldData){
            this.updateAllData();
        }
    },

    computed: {
        totalAmount(){
            return this.data.reduce((total, item) => total + item.total_amount, 0);
        }
    },
}
</script>

<style scoped>
    .image-width{
        width: 80px;
    }
    .mb-20{
        margin-bottom: 20px;
    }
    .pay{
        border-top: 1px solid;
        border-bottom: 1px solid;
        background: #f3f2f7;
    }
</style>